.features .content + .content {
  margin-top: 100px;
}

.features .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #213b52;
}

.features .content ul {
  list-style: none;
  padding: 0;
}

.features .content ul li {
  padding-bottom: 10px;
}

.features .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #fdc134;
}

.features .content p:last-child {
  margin-bottom: 0;
}

.btn-explore-more {
  text-decoration: none;
  color: #213b52;
  border-radius: 50px;
  padding: 8px 35px 10px 35px;
  border: 2px solid #fdc134;
  transition: all ease-in-out 0.3s;
  display: inline-block;
  background: #fdc134;
}

.btn-explore-more:hover {
  background: transparent;
  color: #213b52;
}
