.services {
  padding-bottom: 40px;
}

.services .card {
  border: 0;
  padding: 0 30px;
  margin-bottom: 60px;
  position: relative;
  background: transparent;
}

.services .card-img {
  overflow: hidden;
  z-index: 9;
  border-radius: 0;
}

.services .card-img img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}

.services .card-body {
  z-index: 10;
  background: #fff;
  border-top: 4px solid #fff;
  padding: 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  margin-top: -60px;
  margin-left: 30px;
  width: calc(100% - 60px);
}

@media (max-width: 640px) {
  .services .card-body {
    margin-left: 15px;
    width: calc(100% - 30px);
  }
}

.services .card-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.services .card-title a {
  text-decoration: none;
  color: #213b52;
  transition: 0.3s;
}

.services .card-text {
  color: #5e5e5e;
}

.services .read-more a {
  color: #777777;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  transition: 0.3s;
}

.services .read-more a i {
  font-size: 14px;
  margin-right: 0.3em;
}

.services .read-more a:hover {
  color: #fdc134;
}

.services .card:hover img {
  transform: scale(1.1);
}

.services .card:hover .card-body {
  border-color: #fdc134;
}

.services .card:hover .card-body .card-title a {
  color: #fdc134;
}
