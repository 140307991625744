.hero-section {
  width: 100%;
  height: 100vh;
  padding: 40px 0 0 0;
  background: #22244e;
  background: linear-gradient(120deg, #3e5faa -110%, #22244e 70%, #ff615b 150%);
  /* background: linear-gradient(
    180deg,
      #22244e 0%,
      rgb(33, 60, 84) 35%,
      rgb(32, 69, 100) 100%); */

  .container {
    z-index: 1;
  }

  .container-fluid {
    z-index: 1;
    padding: 0px 200px;
  }
}

.hero-section h1 {
  margin: 0 0 15px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #d2e0ed;
  font-family: "Poppins", sans-serif;
}

.hero-section h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 22px;
}

.hero-section .btn-get-started {
  text-decoration: none;
  color: #213b52;
  border-radius: 50px;
  padding: 8px 35px 10px 35px;
  border: 2px solid #fdc134;
  transition: all ease-in-out 0.3s;
  display: inline-block;
  background: #fdc134;
}

.hero-section .btn-get-started:hover {
  background: transparent;
  color: #fff;
}

@media (max-width: 991px) {
  .hero-section .hero-img {
    text-align: center;
  }

  .hero-section .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .hero-section {
    -moz-text-align-last: center;
    text-align-last: center;
    padding-top: 70px;
  }

  .hero-section h1 {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  }

  .hero-section h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
    text-align: center;
  }

  .hero-section .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  .hero-section .hero-img img {
    width: 80%;
  }
}

.video-hero-section {
  background: none !important;
}

.video-hero-section .video-box {
  width: 100%;
  position: fixed;
  height: 100vh;
  z-index: -1000;
  top: 0;
  left: 0;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000049;
}

.video-hero-section video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}