.team .member {
  text-align: center;
  margin-bottom: 20px;
}

.team .member .pic {
  margin-bottom: 15px;
  overflow: hidden;
  min-height: 260px;
}

.team .member .pic img {
  max-width: 100%;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
  color: #213b52;
}

.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

.team .member .social {
  margin-top: 15px;
}

.team .member .social a {
  color: #5289ba;
  transition: 0.3s;
}

.team .member .social a:hover {
  color: #fdc134;
}

.team .member .social i {
  font-size: 20px;
  margin: 0 2px;
}
