.get-started-btn {
  background: #213b52;
  display: inline-block;
  text-decoration: none;
  padding: 6px 30px;
  border-radius: 20px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  transition: 0.3s;
}

.get-started-btn:hover {
  background: #fdc134;
}
